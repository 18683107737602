import React, { useEffect, useState } from "react"
import { graphql } from 'gatsby'
import Layout from "templates/basic"
import Intro from 'components/intro'
import SEO from 'components/seo'
import Image from "components/image";
import style from 'templates/ContentPageGet/ContentPageGet.module.scss'
import parse from 'html-react-parser';

const Portfolio = ({ data, pageContext }) => {
	let page = null;
	let projects = null;
	if (data.allContentPage && data.allContentPage.edges.length) page = data.allContentPage.edges[0].node;
	if (data.allServicesOption && data.allServicesOption.edges.length) projects = data.allServicesOption.edges;

	projects = projects.map(project => project.node);

	const [newPage, setPage] = useState(page);

	useEffect(() => {
		fetch(`${process.env.GATSBY_ZMS_API_URL}content/page/get/?uri=${page.uri}`)
			.then(response => response.json())
			.then(({ data }) => {
				if (data.length) {
					let page = data[0];
					setPage(page);
				}
			})
			.catch(err => console.log(err));
	}, [page.uri]);

	page = newPage;

	return (
		<>
			<Layout image={page.headerImage} preTitle={page.pageTitle} title={page.subTitle} link={page.link} linkText={page.linkText} enableDanosFoundation={page.danosFoundation} enableFooterTabs={page.danosFooterTab}>
				<Intro pullQuote={page.pullQuote} headline={page.headline} pageCopy={page.pageCopy} />
				<div className="grid-container">
					<div className="grid-x grid-margin-x">
						<div className="cell small-12">
							{page.pageCopy && <hr style={{ margin: "60px auto" }} />}
								{page?.content?.main ? 
									<div className={style.innerContent}>
										{parse(page.content.main)} 
									</div>
								: ''}
						</div>
						{projects.length && projects.map(({ name, photo, description, logos }, index) => {
							return (
								<>
									<div style={{ marginBottom: "3rem" }} className="cell small-12 medium-4">
										<Image imagePadding="100%" src={photo} alt={name} />
									</div>	
									<div style={{ paddingBottom: 0 }} className={`cell small-12 medium-8 ${style.innerContent}`}>
										<h2>{ name }</h2>
										<hr />
										{ parse(description.replace("h3", /h4/g)) }
										<div className={style.tinyImageContainer}>
											{logos.map(logo => {
												return (
													<div className={style.tinyImageWrapper}>
														<img src={logo} alt={name} />
													</div>
												)
											})}
										</div>
									</div>
								</>
							)
						})}
					</div>
				</div>
			</Layout>
			<SEO title={page ? page.pageTitle : ''} />
		</>
	)
}

export default Portfolio

export const query = graphql`
	query {
	 allContentPage(filter: {uri: {eq: "/portfolio/"}, lang: {eq: "en"}, deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
	    edges {
	      node {
	        id
	        uri
	        content {
	          main
	        }
			lang
	        pageTitle
			subTitle
			pullQuote
			pageCopy
			headline
			link
			linkText
			danosFoundation
			danosFooterTab
			headerImage
	      }
	    }
	  }
	  allServicesOption(filter: {deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
		edges {
		  node {
			name
			photo
			description
			logos
		  }
		}
	  }
	}
`